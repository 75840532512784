<template>
  <r-page>
    <template #page-content>
      <div>
        <div class="r-card">
          <h3>Overview</h3>
          <p>
            Button (Tombol) memberi orang cara untuk memicu sebuah tindakan.
            Mereka biasanya ditemukan di formulir, panel dialog, dan dialog.
            Beberapa tombol dikhususkan untuk tugas tertentu, seperti navigasi,
            tindakan berulang, atau penyajian menu.
          </p>
        </div>
        <r-card>
          <div class="columns is-multiline">
            <div class="column is-full">
              <h4>Default</h4>
              <b-button class="is-primary" outlined>Standard</b-button>
              <b-button class="is-primary">Primary</b-button>
              <b-button disabled>Disabled</b-button>
            </div>
            <div class="column is-full">
              <h4>Compound Buttons</h4>
              <b-button class="is-primary is-compound" outlined>
                <span class="button-title">Standard</span>
                <span class="button-subtitle">This is subtitle</span>
              </b-button>
              <b-button class="is-primary is-compound">
                <span class="button-title">Standard</span>
                <span class="button-subtitle">This is subtitle</span>
              </b-button>
              <b-button class="is-compound" disabled>
                <span class="button-title">Standard</span>
                <span class="button-subtitle">This is subtitle</span>
              </b-button>
            </div>
            <div class="column is-full">
              <h4>Command Bar</h4>
              <b-button class="is-command" iconLeft="filter">Filter</b-button>
            </div>
          </div>
        </r-card>
      </div>
    </template>
  </r-page>
</template>
<script>
export default {
  mounted() {
    // console.log('matched', this.$rVar)
  },
}
</script>
